
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','sans-serif';
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}


@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  body {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  body {
    font-size: 15px;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

/* Main Navbar */
nav.navbar {
  background-color: white;
  position: fixed;
  top: 0;
  right: 10;
  width: 100%;
  z-index: 10;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  
}

div.navbar {
  background-color: #f0f0f0;
}

/* Dropdown Menu */
ul.dropdown-menu.show {
  background-color: #f0f0f0;
  width: 100%; /* Ensure it fills the screen width */
  max-width: 100%; /* Prevent any overflow */
  right: 0; /* Align with the parent element (navbar) */
  position: absolute; /* To make sure it's not overflowing */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  overflow: hidden; /* Prevent any horizontal overflow */
  padding: 0; /* Optional, clean up padding */
  margin-left: -60px;
}

/* Dropdown Item Links */
a.dropdown-item {
  color: black;
}

a.nav-link {
  color: black;
  margin-right: 0px;
}

.navbar-brand {
  font-size: 28px;
  position: relative;
  left: 20px;
  color: black;
}

.navbar-brand:hover {
  color: #b1a7ab;
}

.navbar:hover {
  color: #b1a7ab;
}


.home-container{
  
  width: 100vw;
  height: 100%; 
  overflow-y: auto; /* Add vertical scrollbar  */
  background-color: black;

}

.home-container, 
.skills-container,
.about-me-container,
.education-container,
.contact-form,
.card {
  position: relative;
  z-index: 1; /* Add this to all content containers */
}

.page-content {
  padding: 20px; 
}

/* Further reduce size for heading and push it higher */
.heading {
  font-size: 26px; /* Slightly smaller font */
  color: #f0f0f0;
  white-space: pre-line;
  font-family: 'Playfair Display', serif;
  text-align: center;
  width: 100%;
  position: relative;
  top: 8rem; /* Much higher position */
  margin-bottom: 1rem;
  z-index: 2; /* Ensure it appears above other elements */
}

img.name_icon {
  position: relative;
  display: block;
  max-width: 28rem; /* Your reduced size */
  width: 100%;
  margin: 0 auto; /* This centers the element horizontally */
  top: 8rem;
  height: auto;
}


/* Adjust slogan position */
.slogan {
  font-size: 26px; /* Slightly smaller font */
  color: #f0f0f0;
  white-space: pre-line;
  font-family: 'Playfair Display', serif;
  text-align: center;
  width: 100%;
  position: relative;
  top: 11rem; /* Reduced position */
  z-index: 2; /* Ensure it appears above other elements */
}

/* Specific iPhone SE and small mobile optimization */
@media only screen and (max-width: 600px) {
  .heading {
    top: 8rem;
    font-size: 22px;
    padding: 0 10px; /* Add some horizontal padding */
    margin-bottom: 0.5rem;
  }
  
  img.name_icon {
    max-width: 65%; /* Much smaller for mobile */
    height: auto;
    top: 9rem;
  }
  
  .slogan {
    top: 11rem;
    font-size: 22px;
    padding: 0 10px; /* Add some horizontal padding */
  }
}

/* Extra small screens like iPhone SE */
@media only screen and (max-width: 375px) {
  .heading {
    top: 8rem;
    font-size: 20px;
  }
  
  img.name_icon {
    max-width: 60%; /* Even smaller for iPhone SE */
    top: 9rem;
  }
  
  .slogan {
    top: 10rem;
    font-size: 15px;
  }
}




a {
  text-decoration: none;
}




/* contact page css */

form.contact-form{
  background-color: #f0f0f0;
  width:75%;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 3rem;
  border-radius: 12px;


label{
  color:black;
}
  
}
.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
 
}

.form-container {
  max-width: 500px;
  width: 100%;
  
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 150px;
}

button {
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #8c8188;
}


h1.form{
  color: black;
}

/* about page */

.skills-container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 6px;
  color: black;
  font-size: 18px;
  position: relative;
  bottom:3rem;
}

@media only screen and (min-width: 600px) {
  .skills-container {
    width: 60%;
  }
}

@media only screen and (min-width: 900px) {
  .skills-container {
    width: 80%;
    margin: 20px 0;
  }
}


.about-me-container,
.education-container
 {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 6px;
  color: black;
  font-size: 18px;
  justify-content: space-evenly;
}

@media only screen and (min-width: 600px) {
  .about-me-container,

  .education-container {
    width: 60%;
  }
}

@media only screen and (min-width: 900px) {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-me-container,
  .education-container {
    width: 80%;
    margin: 20px 0;
  }
}


.education {
  text-align: center; 
  margin: 20px auto; 
  

}
div.about-me-container{
  margin-bottom: 7rem;
  margin-top: 7rem;
}


div.content-container{
  margin-bottom: 10rem;
  margin-top: 15rem;
  justify-content: space-between;
}

.education-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.education-row > div {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px; 
}

/* slower bouncing animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  animation: bounce 2s infinite; 
  font-size: 5rem;
  flex-wrap: wrap;
}


/* projects page */

#scrollspyHeading3{
  color: white;
}

.card {
  margin: 10px;
  transition: transform 0.3s ease-in-out;
}

.card:hover{
  transform: scale(1.05); 
}

/* Responsive design */
@media only screen and (max-width: 767px) {
  .col {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 992px) {
  .col {
    flex: 0 0 33.3333333%;
  }
}



div.application{
  font-size: 18px;
  color: black;
}

.card-body{
  color: black;
}

.project-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.demo-code-button{
  position: relative;
 margin: auto;
 top:15px;

}

.demo-button,.code-button{

  justify-content: space-evenly;
  position: relative;
  bottom:10px;
 margin:1rem;
align-items: center;
}

.applications-project1,
.applications-project2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; 
  width: 100%;
  gap:2px;
}

.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  flex: 1;
}

.application:hover {
  background-color: #e0e0e0;
}

.application svg {
  margin-bottom: 5px;
}


/* stroll to top */


.scrollToTopButton {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 101;
  color: white; 
  cursor: pointer;
  transition: opacity 0.3s ease;
  font-size: 35px;
}

.scrollToTopButton.show {
  display: block;
}

.scrollToTopButton:hover {
  color: #b1a7ab; 
}

img{
  height: 300px;
}

/* FOOTER  */

.footer-content{
  background-color: #f0f0f0;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;

}

.icon-container{
  color: black;
  font-size: 25px;
 
  
}

/* ballon component */

.lava-lamp-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;
  z-index: -1; /* Ensure it stays behind everything */
}

/* Blob styles */
.blob {
  position: absolute;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(192, 192, 192, 0.8), rgba(192, 192, 192, 0));
  border-radius: 50%;
  filter: blur(50px);
  opacity: 1; /* Increased opacity */
  animation: moveBlob 10s infinite ease-in-out, colorShift 15s infinite ease-in-out;
}

/* Animation for movement */
@keyframes moveBlob {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(200px, 150px);
  }
  50% {
    transform: translate(-100px, 300px);
  }
  75% {
    transform: translate(300px, -100px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Animation for color transitions */
@keyframes colorShift {
  0% {
    background: radial-gradient(circle, rgba(192, 192, 192, 0.8), rgba(192, 192, 192, 0));
  }
  50% {
    background: radial-gradient(circle, rgba(128, 128, 128, 0.8), rgba(128, 128, 128, 0));
  }
  100% {
    background: radial-gradient(circle, rgba(192, 192, 192, 0.8), rgba(192, 192, 192, 0));
  }
}